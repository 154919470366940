// 预约咨询
import service from '@/utils/request'
// 获取后台设置预约须知
export function getItemNotice(params) {
  return service({
    url: '/reserve/memo',
    method: 'get',
    params
  });
}
// 获取预约列表
export function getRecordList(data) {
  return service({
    url: '/reserve/userClient',
    method: 'post',
    data
  });
}
// 取消预约
export function cancelRecord(id,data) {
  return service({
    url: `/reserve/cancel/${id}`,
    method: 'post',
    data
  });
}
// 获取咨询师的可预约列表以及咨询师信息
export function getConsultRecordList(consultId, params) {
  return service({
    url: `/reserve/detail/${consultId}`,
    method: 'get',
    params
  });
}
// 获取预约信息
export function getConsultDetail(data) {
  return service({
    url: '/reserve/info',
    method: 'post',
    data
  });
}
// 用户预约
export function userConsult(data) {
  return service({
    url: '/reserve/save',
    method: 'post',
    data
  });
}
// 预约记录
export function ConsultData(id,params) {
  return service({
    url: `/reserve/record/${id}`,
    method: 'get',
    params
  });
}