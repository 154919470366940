<template>
    <div class="index">
        <!-- 预约须知 -->
        <p class="title">预约须知</p>
       <!-- <div class="container">
             <p v-text="info">
            </p> </div> -->
            <div class="container">
            <el-input type="textarea" autosize placeholder="预约须知" v-model="info"  readonly>
            </el-input>
        </div>
        <div class="read">
            <div class="select" v-if="!select" @click="(select = true)">
                <img src="../../assets/home/noselect.png" alt="">
            </div>
            <div class="select" v-else @click="(select = false)">
                <img src="../../assets/home/hasselect.png" alt="">
            </div>
            <span>阅读并同意《<span class="a">预约须知</span>》</span>
        </div>

        <div class="btn" @click="submit">
            <span>确认</span>
        </div>
    </div>
</template>

<script>
import { getItemNotice } from '@/api/record'
export default {
    data() {
        return {
            info: null,
            select: false
        }
    },
    methods: {
        async getInfo() {
            const res = await getItemNotice();
            console.log(res);
            if (res.code === 200) {
                this.info = res.data
            }
        },
        submit() {
            if (this.select === true) {
                this.$router.push('/home/consulting')
            } else {
                this.$message({
                    showClose: true,
                    message: '请勾选！'
                });
            }

        }
    },
    created() {
        this.getInfo();
    }
}
</script>

<style lang="scss" scoped>
.index {

    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;

    p {
        margin: 0;
        padding: 0;
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        color: #7D7D7D;
    }

    .container {
        // height: 600px;
        // padding: 10px;
        width: 100%;
        // overflow-y: scroll;
        border-radius: 4px;
        // border: 1px solid #EEEEEE;
        margin-top: 10px;
        box-sizing: border-box;

        p {
            font-size: 14px;
            color: #7D7D7D;
        }
    }

    .read {
        margin-top: 10px;
        display: flex;

        span {
            font-size: 14px;
            color: #7D7D7D;

            .a {
                color: var(--custom-color);
                cursor: pointer;
            }
        }

        .select {
            margin-right: 5px;
            display: inline-block;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                height: 15px;
                width: 15px;
            }

            // margin-right: 5px;
            // display: inline-block;
            // height: 10px;
            // width: 10px;
            // border-radius: 50%;
            // border:1px solid #7D7D7D;
        }
    }

    .btn {
        height: 40px;
        border-radius: 3px;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        color: white;
        background: var(--custom-color);
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
        // font-weight: 600;
        font-size: 14px;
        margin-top: 40px;
    }
}

@media screen and (min-width: 751PX) {
    .index {
        padding: 40px 125px;
    }
}

@media screen and (max-width: 750PX) {
    .index {
        padding: 20px;

    }
}
</style>